var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.lastContacted
    ? _c("el-tooltip", { attrs: { placement: "top" } }, [
        _c(
          "div",
          { attrs: { slot: "content" }, slot: "content" },
          _vm._l(_vm.contacted, function (contact, index) {
            return _c("span", { key: index }, [
              _vm._v(
                " • " +
                  _vm._s(
                    _vm._f("dateformat")(contact.date.toDate(), "MMM D, YYYY")
                  ) +
                  " "
              ),
              _c("br"),
            ])
          }),
          0
        ),
        _c(
          "div",
          {
            staticClass: "contact-cell",
            class: { "previous-plan": _vm.lastContact?.previous_plan },
          },
          [
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("dateformat")(_vm.lastContacted, "MMM D, YYYY")
                  ) +
                  " "
              ),
            ]),
            _vm.lastContactedToday
              ? _c("i", { staticClass: "el-icon-check" })
              : _c("i", {
                  staticClass: "el-icon-message contact-button",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleClickContact.apply(null, arguments)
                    },
                  },
                }),
          ]
        ),
      ])
    : _c("div", { staticClass: "contact-cell" }, [
        _c("span", { staticClass: "empty" }, [_vm._v("-")]),
        _c("i", {
          staticClass: "el-icon-message contact-button",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleClickContact.apply(null, arguments)
            },
          },
        }),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }